<template>
  <section class="section-reports">
    <header>
      <Mural
        :breadcrumbs="breadcrumbs"
        :notificationQuantity="0"
        route="home"
        :query="{}"
      />
      <TabNavigation
        class="q-py-sm"
        :tabs="tabs"
        :selectTab="tab"
        @selectedTabs="(value) => changeTab(value)"
      />
    </header>
    <section class="content-report q-py-md">
      <main class="q-px-md">
        <!-- <h4>Escolha os filtros para gerar os relatórios</h4> -->
        <div class="box-form-filter q-py-md">
          <PostPage v-if="tab == 1" />
          <CoursePage v-if="tab == 2" />
          <UsersPage v-if="tab == 3" />
          <WikiPage v-if="tab == 4" />
          <MoodPage v-if="tab == 5" />
          <SurveyPage v-if="tab == 6" />
          <ChatPage v-if="tab == 7" />
          <LightningTestPage v-if="tab == 8" />
          <InteractionPage v-if="tab == 9" />
        </div>
      </main>
    </section>
  </section>
</template>

<script>
//----COMPONENTS
import TabNavigation from "@/components/reports/TabNavigation.vue";
import Mural from "@/components/Mural.vue";

import PostPage from "@/pages/admin/reports/pages/FeedPage.vue";
import CoursePage from "@/pages/admin/reports/pages/CoursePage.vue";
import UsersPage from "@/pages/admin/reports/pages/UsersPage.vue";
import WikiPage from "@/pages/admin/reports/pages/WikiPage.vue";
import SurveyPage from "@/pages/admin/reports/pages/SurveyPage.vue";
import MoodPage from "@/pages/admin/reports/pages/MoodPage.vue";
import ChatPage from "@/pages/admin/reports/pages/ChatPage.vue";
import LightningTestPage from "@/pages/admin/reports/pages/LightningTestPage.vue";
import InteractionPage from "@/pages/admin/reports/pages/InteractionPage.vue";

//----SERVICES

import PostDataServices from "@/services/PostDataServices";

//----UTILS
import CanUserAccessClass from "@/utils/CanUserAccessClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    TabNavigation,
    PostPage,
    CoursePage,
    UsersPage,
    WikiPage,
    MoodPage,
    Mural,
    ChatPage,
    SurveyPage,
    LightningTestPage,
    InteractionPage,
  },
  setup() {
    //------SERVICES

    //------CONSTATNS
    const canUserAccessClass = new CanUserAccessClass();
    const postDataServices = new PostDataServices();
    const store = useStore();

    const tabs = ref([
      {
        value: 1,
        name: "Posts",
        permission: !validateActionsPage("analytic-reports-posts"),
      },
      {
        value: 2,
        name: "Cursos",
        permission: !validateActionsPage("analytic-reports-courses"),
      },
      {
        value: 3,
        name: "Usuários",
        permission: !validateActionsPage("analytic-reports-users"),
      },
      {
        value: 4,
        name: "Wiki",
        permission: !validateActionsPage("analytic-reports-wikis"),
      },
      {
        value: 5,
        name: "Humor",
        permission: !validateActionsPage("analytic-reports-humor"),
      },
      {
        value: 6,
        name: "Enquetes",
        permission: !validateActionsPage("analytic-reports-posts"),
      },
      {
        value: 7,
        name: "Chats",
        permission: !validateActionsPage("chat-add-groups"),
      },
      {
        value: 8,
        name: "Teste relâmpago",
        permission: !validateActionsPage("analytic-reports-posts"),
      },
      {
        value: 9,
        name: "Interação",
        permission: !validateActionsPage("analytic-reports-users"),
      },
    ]);

    //------VARIABLES
    let tab = ref(1);
    let breadcrumbs = ref(["Relatórios"]);

    onMounted(() => {
      //filterQualiOn();
    });

    function changeTab(value) {
      tab.value = value;
    }

    async function validateForm(form) {
      console.log(form);
    }

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    async function filterQualiOn() {
      const { company_id } = store.state.userdata;

      await postDataServices
        .getAllCategories(company_id)
        .then((response) => {
          response.data.find((item) => {
            if (item.id == 0) {
              return tabs.value.push({
                value: 1,
                name: "Posts",
              });
            } else {
              return tabs.value.push(
                {
                  value: 1,
                  name: "Posts",
                },
                {
                  value: 2,
                  name: "Cursos",
                },
                {
                  value: 3,
                  name: "Usuários",
                },
                {
                  value: 4,
                  name: "Wiki",
                }
              );
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    return {
      //-----CONSTANTS

      //-----VARIABLES
      tab,
      tabs,
      breadcrumbs,

      //-----FUNCTIONS
      changeTab,
      validateForm,
      validateActionsPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.section-reports {
  width: 100%;
  height: 100%;
  padding: 16px;
}
.content-report {
  max-height: 79%;
  overflow-y: auto;

  main {
    overflow-y: auto;

    h5 {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #2f2f2f;
      font-family: Montserrat;
    }

    h4 {
      font: 1.2rem "Montserrat", sans-serif;
    }

    .box-form-filter {
      // height: 65vh;
      overflow-y: auto;
      // border: 1.5px solid #C4C4C4;
      // box-shadow: 3px 3px 8px rgba(196, 196, 196, 0.15);
      // border-radius: 50px;
    }
  }
}

.avoid-clicks {
  pointer-events: none;
}
</style>
