<template>
  <div>
    <SurveyForm
      :listGroups="listGroups"
      :listcategory="listCategories"
      :listCreatorPost="listCreatorPost"
      :listSurveys="listSurveys"
      @sendForm="(form) => validateForm(form)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import SurveyForm from "@/components/reports/forms/SurveyForm.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import RoleServices from "@/services/RoleServices";
import CompanyServices from "@/services/CompanyServices";
import PostDataServices from "@/services/PostDataServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "SurveyPage",

  components: {
    SurveyForm,
  },
  setup() {
    //------SERVICES
    const _RoleService = new RoleServices();
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _companyServices = new CompanyServices();
    const _postDataServices = new PostDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------VARIABLES
    let listCompanies = ref([]);
    let listUsers = ref([]);
    let listCreatorPost = ref([]);
    let listProfiles = ref([]);
    let listGroups = ref([]);
    let listCategories = ref([]);
    let listSurveys = ref([]);

    onMounted(() => {
      _getAllRoles();
      _getAllGroups();
      _getAllCompanies();
      _getAllCategories();
      _getAllCreatorPost();
      getSurveys();
    });

    async function _getAllRoles() {
      _RoleService.showRoles().then((response) => {
        response.data.map((item) => {
          listProfiles.value.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    }

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCreatorPost() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2 || item.role_id == 1) {
              listCreatorPost.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCompanies() {
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      await _postDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.description,
              value: item.id,
            });
          });
        });
    }

    function getSurveys() {
      _postDataServices
        .getAllPoll()
        .then((response) => {
          response.data.forEach((survey) => {
            const { id, title, your_question } = survey;

            listSurveys.value.push({
              label: title,
              caption: your_question,
              value: id,
            });
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    async function validateForm(form) {
      const formData = new FormData();

      formData.append("Company", store.state.userdata.company_id);

      if (form.formData.Category != null) {
        formData.append("Category", form.formData.Category);
      }

      if (form.formData.Post != null) {
        formData.append("Post", form.formData.Post);
      }

      if (form.formData.Groups.length > 0) {
        form.formData.Groups.forEach((group) => {
          formData.append("Group", group);
        });
      }

      if (form.formData.CreatePost.length > 0) {
        form.formData.CreatePost.forEach((createPost) => {
          formData.append("Tutor", createPost);
        });
      }

      /*  formData.append("Date_Start", form.formData.Date_Start);
      formData.append("Date_End", form.formData.Date_End); */

      await _ReportsServices
        .reportPoll(form.id, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-enquete.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    return {
      //------VARIABLES
      listCompanies,
      listUsers,
      listProfiles,
      listGroups,
      listCategories,
      listCreatorPost,
      listSurveys,
      //------FUNCTIONS
      validateForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}
</style>
