<template>
  <div class="container">
    <div class="q-mb-md">
      <label for="">Status do grupo</label>
      <q-toggle v-model="formChatReport.status" color="green" />
    </div>
    <div class="container-inputs">
      <div class="form-group">
        <label for="">Nome do grupo</label>
        <q-select
          v-model="formChatReport.groupsId"
          :options="optionsChats"
          dense
          use-chips
          use-input
          map-options
          emit-value
          multiple
          @filter="filterFnGroups"
          outlined
          color="default-pink"
          bg-color="grey-3"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                Não encontrado
              </q-item-section>
            </q-item>
          </template></q-select
        >
      </div>
      <div class="form-group">
        <label for="">Criador do grupo</label>
        <q-select
          v-model="formChatReport.createBy"
          :options="optionsUsers"
          dense
          use-chips
          emit-value
          outlined
          map-options
          use-input
          @filter="filterFnCreators"
          multiple
          color="default-pink"
          bg-color="grey-3"
        >
          <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label v-html="opt.label" />
                <q-item-label caption v-html="opt.caption" />
              </q-item-section>
              <q-item-section side>
                <q-toggle
                  :model-value="selected"
                  @update:model-value="toggleOption(opt)"
                />
              </q-item-section>
            </q-item> </template
        ></q-select>
      </div>
    </div>
    <div class="q-mt-md">
      <q-btn
        color="default-pink"
        icon="check"
        label="Gerar relatório"
        @click="generateReport"
        rounded
      />
    </div>
  </div>
</template>

<script>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";

import ChatServices from "@/services/ChatServices";
import UserServices from "@/services/UserServices";
import ReportsServices from "@/services/ReportsServices";

export default {
  name: "ChatForm",
  setup() {
    const formChatReport = reactive({
      groupsId: [],
      createBy: [],
      status: true,
    });
    const chatServices = new ChatServices();
    const userServices = new UserServices();
    const store = useStore();
    const _ReportsServices = new ReportsServices();
    const listChats = ref([]);
    const optionsChats = ref([]);
    const listUsers = ref([]);
    const optionsUsers = ref([]);

    onMounted(() => {
      getAllChats();
      getAllCreators();
    });

    async function getAllCreators() {
      await userServices
        .getAllUser()
        .then((response) => {
          response.data.forEach((user) => {
            const { id, first_name, last_name, role_id } = user;
            if ([1, 2].includes(role_id)) {
              listUsers.value.push({
                label: `${first_name} ${last_name}`,
                value: id,
              });
            }
          });
          optionsUsers.value = listUsers.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllChats() {
      await chatServices
        .getAllChats()
        .then((response) => {
          response.data.forEach((chat) => {
            const { is_group, conversationId, name } = chat;
            if (is_group) {
              listChats.value.push({ label: name, value: conversationId });
            }
          });
          optionsChats.value = listChats.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function generateReport() {
      const formData = new FormData();

      const { groupsId, createBy, status } = formChatReport;

      formData.append("Company", store.state.userdata.company_id);

      if (groupsId.length !== 0) {
        groupsId.forEach((id) => formData.append("Group", id));
      }

      if (createBy.length !== 0) {
        createBy.forEach((id) => formData.append("Creator", id));
      }

      formData.append("Status", status);

      await _ReportsServices
        .reportChat(9, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-chat.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    function filterFnGroups(val, update) {
      if (val == "") {
        update(() => {
          optionsChats.value == listChats.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsChats.value = listChats.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }
    function filterFnCreators(val, update) {
      if (val == "") {
        update(() => {
          optionsUsers.value == listUsers.value;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsUsers.value = listUsers.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    return {
      formChatReport,
      generateReport,
      optionsChats,
      optionsUsers,
      filterFnGroups,
      filterFnCreators,
    };
  },
};
</script>

<style scoped>
.container {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #2f2f2f;
}

label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.25px;
  color: #141519;
  margin-block: 0.25rem;
}

.container-inputs {
  display: flex;
  justify-content: space-between;
}

.form-group {
  width: calc(50% - 10px);
}
</style>
