<template>
  <q-select
    bg-color="grey-3"
    color="default-pink"
    class="col-11"
    v-model="model"
    use-input
    input-debounce="0"
    :multiple="isMultiple"
    :options="listData"
    emit-value
    map-options
    outlined
    use-chips
    dense
    @filter="filterFn"
    @update:model-value="$emit('selectValue', model)"
  >
    <template v-slot:no-option>
      <q-item>
        <q-item-section class="text-grey"> Não encontrado </q-item-section>
      </q-item>
    </template>
    <template v-slot:option="{ itemProps, opt, selected, toggleOption }">
      <q-item v-bind="itemProps">
        <q-item-section>
          <q-item-label v-html="opt.label" />
        </q-item-section>
        <q-item-section side>
          <q-toggle
            :model-value="selected"
            @update:model-value="toggleOption(opt)"
          />
        </q-item-section>
      </q-item>
    </template>
  </q-select>
</template>

<script>
//----VUEJS
import { ref } from "vue";

export default {
  name: "SelectWithSearch",
  props: {
    options: Array,
    listOption: Array,
    label: String,
    isMultiple: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["selectValue"],
  setup(props) {
    const model = ref();
    const listData = ref(props.options);

    function filterFn(val, update) {
      if (val === "") {
        update(() => {
          listData.value = props.options;
        });
        return;
      }
      update(() => {
        const search = val.toLowerCase();
        listData.value = props.options.filter(
          (option) => option.label.toLowerCase().indexOf(search) > -1
        );
      });
    }

    return {
      /* const */
      model,
      listData,
      /* functions */
      filterFn,
    };
  },
};
</script>
<style scoped></style>
