<template>
  <div>
    <PostForm
      :listGroups="listGroups"
      :listLider="listUsers"
      :listcategory="listCategories"
      :listCreatorPost="listCreatorPost"
      :getPostByTitle="getPostByTitle"
      @sendForm="(form) => validateForm(form)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import PostForm from "@/components/reports/forms/PostForm.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import RoleServices from "@/services/RoleServices";
import CompanyServices from "@/services/CompanyServices";
import PostDataServices from "@/services/PostDataServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "FeedPage",

  components: {
    PostForm,
  },
  setup() {
    //------SERVICES
    const _RoleService = new RoleServices();
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _companyServices = new CompanyServices();
    const _postDataServices = new PostDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------VARIABLES
    let listCompanies = ref([]);
    let listUsers = ref([]);
    let listCreatorPost = ref([]);
    let listProfiles = ref([]);
    let listGroups = ref([]);
    let listCategories = ref([]);

    onMounted(() => {
      _getAllRoles();
      _getAllGroups();
      _getAllUsers();
      _getAllCompanies();
      _getAllCategories();
      _getAllCreatorPost();
    });

    async function _getAllRoles() {
      _RoleService.showRoles().then((response) => {
        response.data.map((item) => {
          listProfiles.value.push({
            label: item.name,
            value: item.id,
          });
        });
      });
    }

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCreatorPost() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2 || item.role_id == 1) {
              listCreatorPost.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCompanies() {
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      await _postDataServices
        .getAllCategories(store.state.userdata.company_id)
        .then((response) => {
          response.data.map((item) => {
            listCategories.value.push({
              label: item.description,
              value: item.id,
            });
          });
        });
    }

    async function getPostByTitle(title) {
      const PAGE_SIZE = 50;
      const PAGE_NUMBER = 1;
      return await _postDataServices.getPostsByTitle(
        PAGE_NUMBER,
        PAGE_SIZE,
        title
      );
    }

    async function validateForm(form) {
      const formData = new FormData();
      //
      formData.append("Company", store.state.userdata.company_id);

      if (form.formData.Lider.length > 0) {
        form.formData.Lider.forEach((lider) => {
          formData.append("Lider", lider);
        });
      }
      if (form.formData.Category.length > 0) {
        form.formData.Category.forEach((category) => {
          formData.append("Category", category);
        });
      }
      if (form.formData.Post.length > 0) {
        form.formData.Post.forEach((post) => {
          formData.append("Post", post);
        });
      }

      if (form.formData.Users.length > 0) {
        form.formData.Users.forEach((user) => {
          formData.append("Users", user);
        });
      }

      if (form.formData.Groups.length > 0) {
        form.formData.Groups.forEach((group) => {
          formData.append("Groups", group);
        });
      }

      if (form.formData.CreatePost.length > 0) {
        form.formData.CreatePost.forEach((createPost) => {
          formData.append("CreatePost", createPost);
        });
      }

      formData.append("Date_Start", form.formData.Date_Start);
      formData.append("Date_End", form.formData.Date_End);

      await _ReportsServices
        .reportPost(form.id, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-posts.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    return {
      //------VARIABLES
      listCompanies,
      listUsers,
      listProfiles,
      listGroups,
      listCategories,
      listCreatorPost,

      //------FUNCTIONS
      getPostByTitle,
      validateForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}
</style>
