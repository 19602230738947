<template>
  <div>
    <UsersForm
      :listGroups="listGroups"
      :listLider="listUsers"
      @sendForm="(form) => validateForm(form)"
      @generate="(id) => generateExcel(id)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import UsersForm from "@/components/reports/forms/UsersForm.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "UsersPage",

  components: {
    UsersForm,
  },
  setup() {
    //------SERVICES
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------VARIABLES
    let listUsers = ref([]);
    let listGroups = ref([]);

    onMounted(() => {
      _getAllGroups();
      _getAllUsers();
    });

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("Company", store.state.userdata.company_id);
      if (form.Lider.length > 0) {
        form.Lider.forEach((lider) => {
          formData.append("Lider", lider);
        });
      }
      if (form.Groups.length > 0) {
        form.Groups.forEach((group) => {
          formData.append("Groups", group);
        });
      }

      formData.append("Date_Start", form.Date_Start);
      formData.append("Date_End", form.Date_End);

      await _ReportsServices
        .reportUsers(3, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-usuarios.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    async function generateExcel(groupId) {
      await _ReportsServices
        .reportUsersByGroup(groupId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "usuarios.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          Alerts.alertOf("Erro ao gerar relatório, por favor tente mais tarde");
        });
    }

    return {
      //------VARIABLES
      listUsers,
      listGroups,

      //------FUNCTIONS
      validateForm,
      generateExcel,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}
</style>
