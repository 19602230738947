<template>
  <div>
    <WikiForm
      :listGroups="listGroups"
      :listcategory="listCategories"
      :listCreatorWiki="listCreatorPost"
      :getWikiByTitle="getWikiByTitle"
      @sendForm="(form) => validateForm(form)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import WikiForm from "@/components/reports/forms/WikiForm.vue";

//----SERVICES
import GroupServices from "@/services/GroupServices";
import UserServices from "@/services/UserServices";
import WikiDataServices from "@/services/WikiDataServices";
import ReportsServices from "@/services/ReportsServices";
import PostDataServices from "@/services/PostDataServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----QUASAR
import { useQuasar } from "quasar";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

export default {
  name: "FeedPage",

  components: {
    WikiForm,
  },
  setup() {
    //------SERVICES
    const _GroupService = new GroupServices();
    const _UserService = new UserServices();
    const _postDataServices = new PostDataServices();
    const _wikiDataServices = new WikiDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();

    //----UTILS
    const Alerts = new AlertsClass();

    //------CONSTATNS
    const $q = new useQuasar();

    //------VARIABLES
    let listCreatorPost = ref([]);
    let listProfiles = ref([]);
    let listGroups = ref([]);
    let listCategories = ref([]);

    onMounted(() => {
      _getAllGroups();
      _getAllUsers();
      _getAllCategories();
    });

    async function _getAllGroups() {
      _GroupService
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllUsers() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.role_id == 2 || item.role_id == 1) {
              listCreatorPost.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCategories() {
      const company_id = store.state.userdata.company_id;
      await _postDataServices.getAllCategories(company_id).then((response) => {
        response.data.map((item) => {
          listCategories.value.push({
            label: item.description,
            value: item.id,
          });
        });
      });
    }

    async function getWikiByTitle(title) {
      return await _wikiDataServices.getForTitle(title);
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("Company", store.state.userdata.company_id);

      if (form.Category.length > 0) {
        form.Category.forEach((category) => {
          formData.append("Category", category);
        });
      }
      if (form.Author.length > 0) {
        form.Author.forEach((author) => {
          formData.append("Author", author);
        });
      }
      if (form.Wiki.length > 0) {
        form.Wiki.forEach((wiki) => {
          formData.append("Wiki", wiki);
        });
      }
      formData.append("Date_Start", form.Date_Start);
      formData.append("Date_End", form.Date_End);

      await _ReportsServices
        .reportWiki(6, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-wikis.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    return {
      //------VARIABLES
      listProfiles,
      listGroups,
      listCategories,
      listCreatorPost,

      //------FUNCTIONS
      getWikiByTitle,
      validateForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}
</style>
