<template>
  <div class="container">
    <div class="header">
      <h1>Filtros</h1>
    </div>
    <div class="form-report">
      <div class="select-inputs">
        <div>
          <label for="leader">Líder</label>
          <SelectWithSearch
            :options="listUsers"
            :listOption="listUsers"
            @selectValue="(val) => (formInteractionReport.leaders = val)"
            :label="'Líderes'"
          />
        </div>
        <div>
          <div class="row items-center">
            <label class="q-mr-sm">Segmentos</label>
            <q-img
              src="@/assets/icons/info_cicle.svg"
              width="16px"
              height="16px"
            />
            <q-tooltip anchor="top left" self="top left">
              Utilize os segmentos para poder filtrar os grupos
            </q-tooltip>
          </div>
          <q-select
            dense
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            id="semgments"
            multiple
            use-chips
            use-input
            emit-value
            map-options
            @filter="filterFnBranches"
            v-model="formInteractionReport.branches"
            :options="listBranches"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <div class="container-groups">
          <div class="item-groups">
            <label for="">Grupos</label>
            <q-select
              dense
              outlined
              color="default-pink"
              bg-color="grey-3"
              class="col-11"
              multiple
              use-input
              use-chips
              emit-value
              @filter="filterFnGroups"
              :disable="
                formInteractionReport.branches.length > 0 ? false : true
              "
              map-options
              v-model="formInteractionReport.groups"
              :options="optionsGroups"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <q-checkbox
            left-label
            v-model="allGroups"
            label="Selecionar todos"
            :disable="formInteractionReport.branches.length > 0 ? false : true"
            color="default-pink"
            @update:model-value="selectedFilteredGroups"
          />
        </div>
      </div>
      <div class="radio-inputs">
        <div class="row">
          <h2 class="q-mr-sm">Período</h2>
          <q-img
            src="@/assets/icons/info_cicle.svg"
            width="16px"
            height="16px"
          />
          <q-tooltip anchor="top left" self="top left">
            O período é baseado na data de criação dos usuários
          </q-tooltip>
        </div>
        <div class="q-gutter-xl row wrap">
          <div v-for="(item, index) in period" :key="index">
            <q-radio
              v-model="periodSelect"
              :val="item.label"
              :label="item.label"
              color="default-pink"
              @update:model-value="(period) => setDataByPeriod(item)"
            />
          </div>
        </div>
      </div>
      <div class="custom-date">
        <div>
          <label for="from">De</label>
          <q-input
            dense
            v-model="formInteractionReport.Date_Start"
            id="from"
            type="date"
            outlined
            bg-color="grey-3"
          />
        </div>
        <div>
          <label for="from">Até</label>
          <q-input
            v-model="formInteractionReport.Date_End"
            id="from"
            dense
            type="date"
            outlined
            bg-color="grey-3"
          />
        </div>
      </div>
    </div>
    <div class="self-end q-gutter-md">
      <q-btn
        color="default-pink"
        size="md"
        class="q-py-md q-mt-md"
        no-caps
        @click="generateReport(11)"
        rounded
      >
        <label for="" class="q-mr-sm cursor-pointer"
          >Gerar relatório de consumo de conteúdos</label
        >
        <Reports :color="'#fff'" />
      </q-btn>
      <q-btn
        color="default-pink"
        size="md"
        class="q-py-md q-mt-md"
        no-caps
        @click="generateReport(12)"
        rounded
      >
        <label for="" class="q-mr-sm cursor-pointer"
          >Gerar relatório de criação de conteúdos</label
        >
        <Reports :color="'#fff'" />
      </q-btn>
    </div>
  </div>
</template>

<script>
import Reports from "../../../assets/icons/menu/Reports.vue";
import Select from "../inputs/Select.vue";

import UserServices from "@/services/UserServices";
import GroupServices from "@/services/GroupServices";

import ReportsServices from "@/services/ReportsServices";

import AlertsClass from "@/utils/Notifications/AlertsClass";

import { reactive, ref, onMounted, watch } from "vue";

/* Components */
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";

import { useStore } from "vuex";

export default {
  name: "InteractionForm",
  components: {
    Select,
    Reports,
    SelectWithSearch,
  },
  setup(props) {
    /* Services */
    const _UserServices = new UserServices();
    const _GroupServices = new GroupServices();
    const _ReportsServices = new ReportsServices();

    //----UTILS
    const Alerts = new AlertsClass();

    const store = useStore();

    let formInteractionReport = reactive({
      leaders: [],
      groups: [],
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
      branches: [],
    });
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let allGroups = ref(false);

    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },

      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);

    const periodSelect = ref(null);
    const listUsers = ref([]);
    const listGroups = ref([]);
    const optionsGroups = ref([]);

    async function getAllUsers() {
      await _UserServices
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if (item.is_leader) {
              listUsers.value.push({
                label: item.first_name + " " + item.last_name,
                value: item.id,
              });
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getAllGroups() {
      _GroupServices
        .listGroups()
        .then((response) => {
          response.data.map((item) => {
            listGroups.value.push({
              label: item.group_name,
              value: item.id,
              company_id: item.company_id,
              branch_id: item.branch_id,
            });
          });
          optionsGroups.value = listGroups.value;
        })
        .catch((error) => {
          console.log(error);
        });
    }

    async function getBranches() {
      await _GroupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          optionsGroups.value = listGroups.value.filter((group) => {
            return formInteractionReport.branches.includes(group.branch_id);
          });
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          return (
            v.label.toLowerCase().indexOf(needle) > -1 &&
            formInteractionReport.branches.includes(v.branch_id)
          );
        });
      });
    }

    async function generateReport(id) {
      const formData = new FormData();

      formData.append("Company", store.state.userdata.company_id);

      if (formInteractionReport.Date_Start != null || undefined) {
        formData.append("Date_Start", formInteractionReport.Date_Start);
      }

      if (formInteractionReport.Date_End != null || undefined) {
        formData.append("Date_End", formInteractionReport.Date_End);
      }

      if (formInteractionReport.groups.length != 0) {
        formInteractionReport.groups.forEach((group) => {
          formData.append("Groups", group);
        });
      }

      if (formInteractionReport.leaders.length != 0) {
        formInteractionReport.leaders.forEach((leader) => {
          formData.append("Lider", leader);
        });
      }

      await _ReportsServices
        .reportInteracation(id, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-interação.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formInteractionReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formInteractionReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formInteractionReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formInteractionReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formInteractionReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formInteractionReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formInteractionReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formInteractionReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formInteractionReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formInteractionReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    onMounted(() => {
      getAllUsers();
      getAllGroups();
      getBranches();
    });

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!formInteractionReport.groups.includes(group.value)) {
            formInteractionReport.groups.push(group.value);
          }
        });
      } else {
        formInteractionReport.groups = null;
        formInteractionReport.groups = [];
      }
    }

    watch(
      () => formInteractionReport.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          formInteractionReport.groups = null;
          formInteractionReport.groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = listGroups.value;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = listGroups.value.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          formInteractionReport.groups = groupValues.filter((value) => {
            if (formInteractionReport.groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && formInteractionReport.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!formInteractionReport.groups.includes(group.value)) {
              formInteractionReport.groups.push(group.value);
            }
          });
        }
      }
    });
    return {
      period,
      periodSelect,
      listUsers,
      listGroups,
      formInteractionReport,
      generateReport,
      setDataByPeriod,
      listBranches,
      filterFnBranches,
      allGroups,
      selectedFilteredGroups,
      optionsGroups,
      filterFnGroups,
    };
  },
};
</script>

<style scoped>
.container {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #2f2f2f;
}

.form-report {
  margin-top: 2.125rem;
  display: flex;
  flex-direction: column;
}

.form-report label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.25px;
  color: #141519;
  margin-block: 0.25rem;
}

.select-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-inputs div + div {
  margin-top: 20px;
}

.radio-inputs {
  margin-top: 1.75rem;
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 1.125rem;
}

.custom-date {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
}

.custom-date label {
  margin-bottom: 0.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #c4c4c4;
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}

@media (min-width: 600px) {
  .custom-date {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .custom-date div {
    width: calc(187px);
  }
}
</style>
