<template>
  <form
    class="q-px-lg"
    @submit.prevent.stop="
      validateForm() ? $emit('sendForm', formWikiReport) : null
    "
  >
    <div class="row col-12 justify-between q-pb-sm">
      <div class="col-3">
        <div>
          <span>Categoria</span>
        </div>
        <div class="row">
          <SelectWithSearch
            :options="optionscategory"
            :listOption="listcategory"
            label="Categorias"
            @selectValue="(val) => (formWikiReport.Category = val)"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span>Autor da wiki</span>
        </div>
        <div class="row">
          <SelectWithSearch
            :options="optionsCreatorWiki"
            :listOption="listCreatorWiki"
            label="Usuários"
            @selectValue="(val) => (formWikiReport.Author = val)"
          />
        </div>
      </div>
      <div class="col-3">
        <div>
          <span>Wiki</span>
        </div>
        <div class="row">
          <SelectSearch
            :searchFunction="filterWiki"
            :filterFunction="formatItem"
            @selectValue="(val) => (formWikiReport.Wiki = val)"
          />
        </div>
      </div>
    </div>

    <div class="row col-12 justify-between q-pb-sm">
      <div class="col-3">
        <div>
          <span>Período</span>
        </div>
        <div class="row">
          <q-select
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="periodSelect"
            :options="period"
            @update:model-value="(period) => setDataByPeriod(period)"
          />
        </div>
      </div>

      <div class="col-3">
        <div>
          <span>De</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="formWikiReport.Date_Start"
          />
        </div>
      </div>

      <div class="col-3">
        <div>
          <span>Até</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            :rules="[
              (val) =>
                val >= formWikiReport.Date_Start ||
                'Por favor selecione uma data pós o dia inicial',
            ]"
            v-model="formWikiReport.Date_End"
          />
        </div>
      </div>
    </div>
    <!-- 
    <div class="row col-12 justify-between q-pb-sm">
      <div class="col-3 column">
        <div>
          <span>Tipos de wiki</span>
        </div>
        <div class="column">
          <q-toggle v-model="formWikiReport.Wiki_Inactive" label="Inativos" />
          <q-toggle v-model="formWikiReport.Wiki_Active" label="Ativos" />
        </div>
      </div>
    </div> -->

    <footer class="q-py-md">
      <div class="row justify-end">
        <q-btn
          unelevated
          rounded
          color="default-pink"
          label="gerar relatório"
          type="submit"
        />
      </div>
    </footer>
  </form>
</template>

<script>
//----VUEJS
import { reactive, ref } from "vue";

//----QUASAR
import { useQuasar } from "quasar";

//----COMPONENTS
import Select from "@/components/reports/inputs/Select.vue";
import SelectSearch from "@/components/reports/inputs/SelectSearch.vue";
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";

export default {
  name: "WikiForm",
  components: {
    Select,
    SelectSearch,
    SelectWithSearch,
  },
  props: {
    listGroups: Array,
    listcategory: Array,
    listCreatorWiki: Array,
    getWikiByTitle: Function,
  },

  emits: ["sendForm"],

  setup(props) {
    //------CONSTATNS
    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Essa semana",
        type: 4,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Mês atual",
        type: 3,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 3 meses",
        start: -3,
        end: 0,
        type: 2,
      },
      {
        label: "Ano atual",
        type: 5,
      },
      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);
    const $q = new useQuasar();

    //------VARIABLES
    let formWikiReport = reactive({
      Category: [],
      Author: [],
      Wiki: [],
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
      Wiki_Active: false,
      Wiki_Inactive: false,
    });

    let periodSelect = ref(null);

    let optionsGroups = ref(props.listGroups);
    let optionsCreatorWiki = ref(props.listCreatorWiki);
    let optionscategory = ref(props.listcategory);

    function filterSelect(val, update, options, list) {
      if (val === "") {
        update(() => {
          options.value = list.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options.value = list.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function formatItem(item) {
      return {
        label: item.title,
        value: item.wikiId,
      };
    }

    function addSelect(formValue, currentValue) {
      formValue = currentValue;
    }

    async function filterWiki(val, update) {
      return await props.getWikiByTitle(val);
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formWikiReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formWikiReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formWikiReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formWikiReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formWikiReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formWikiReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formWikiReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formWikiReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formWikiReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formWikiReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    function validateForm() {
      // if (formWikiReport.Category.length < 1) {
      //   _alertOf("Categoria é obrigatório");
      //   return false;
      // }

      return true;
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    return {
      //------CONSTATNS
      period,

      //------VARIABLES
      formWikiReport,
      optionsGroups,
      optionsCreatorWiki,
      optionscategory,

      periodSelect,

      //------FUNCTIONS
      filterSelect,
      addSelect,
      filterWiki,
      setDataByPeriod,
      validateForm,
      formatItem,
    };
  },
};
</script>

<style lang="scss" scoped></style>
