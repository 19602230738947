<template>
  <InteractionForm />
</template>
<script>
import InteractionForm from "@/components/reports/forms/InteractionForm.vue";
export default {
  name: "InteractionPage",
  components: {
    InteractionForm,
  },
};
</script>
