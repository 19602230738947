<template>
  <div>
    <CourseForm
      :listCourse="listCourse"
      :listTrail="listTrail"
      :listTutor="listTutor"
      :listStutendInTrail="listStutendInTrail"
      :listcategory="listCategories"
      @getCourseId="(course) => getCourse(course)"
      @sendForm="(form) => validateForm(form)"
    />
  </div>
</template>

<script>
//----COMPONENTS
import CourseForm from "@/components/reports/forms/CourseForm.vue";

//----SERVICES
import UserServices from "@/services/UserServices";
import TrailDataServices from "@/services/TrailDataServices";
import ReportsServices from "@/services/ReportsServices";

//----UTILS
import AlertsClass from "@/utils/Notifications/AlertsClass";

//----VUEJS
import { ref, onMounted } from "vue";
import { useStore } from "vuex";

//----QUASAR
import { useQuasar } from "quasar";

export default {
  name: "CoursePage",

  components: {
    CourseForm,
  },
  setup() {
    //------SERVICES
    const _UserService = new UserServices();
    const _trailDataServices = new TrailDataServices();
    const _ReportsServices = new ReportsServices();
    const store = useStore();
    const company_id = store.state.userdata.company_id;
    //----UTILS
    const Alerts = new AlertsClass();

    //------CONSTATNS
    const $q = new useQuasar();

    //------VARIABLES
    let listTutor = ref([]);
    let listCourse = ref([]);
    let listTrail = ref([]);
    let listStutendInTrail = ref([]);
    let listCategories = ref([]);

    onMounted(() => {
      _getAllTutors();
      _getAllCourses();
      _getAllCategories();
    });

    async function _getAllTutors() {
      await _UserService
        .getAllUser()
        .then((response) => {
          response.data.map((item) => {
            if ([1, 2].includes(item.role_id)) {
              const { id, first_name, last_name, role_name } = item;
              listTutor.value.push({
                label: `${first_name} ${last_name}`,
                value: id,
                caption: role_name,
              });
            }
          });
        })
        .catch((e) => {
          console.log("deu ruims " + e);
        });
    }

    async function _getAllCourses() {
      _trailDataServices
        .getAllCoursesForReport()
        .then((response) => {
          response.data.map((item) => {
            listCourse.value.push({
              label: item.title,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllTrails(courseId) {
      _trailDataServices
        .getAllTraining(courseId)
        .then((response) => {
          response.data.map((item) => {
            let trail = {
              label: item.training_title,
              value: item.id,
            };

            if (listTrail.value.length < 1) {
              listTrail.value.push(trail);
            } else if (_isInList(listTrail.value, trail)) {
              listTrail.value.push(trail);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    function _isInList(List, newItem) {
      let isEqual = true;
      List.forEach((item) => {
        if (item.value == newItem.value) {
          isEqual = false;
        }
      });

      return isEqual;
    }

    async function _getAllStudentInTrails(courseId) {
      _trailDataServices
        .getStudentsInTrail(courseId)
        .then((response) => {
          response.data.map((item) => {
            let user = {
              label: item.first_name + " " + item.last_name,
              value: item.user_id,
            };
            if (listStutendInTrail.value.length < 1) {
              listStutendInTrail.value.push(user);
            } else if (_isInList(listStutendInTrail.value, user)) {
              listStutendInTrail.value.push(user);
            }
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function _getAllCategories() {
      _trailDataServices
        .getCategories(company_id)
        .then((response) => {
          response.data.map((item) => {
            // if (item.length < 1) {
            // }

            let trail = {
              label: item.name,
              value: item.id,
            };
            listCategories.value.push(trail);
          });
        })
        .catch((e) => {
          console.log("deu ruim " + e);
        });
    }

    async function getCourse(courses) {
      courses.forEach(async (course) => {
        await _getAllTrails(course);
        await _getAllStudentInTrails(course);
      });
    }

    async function validateForm(form) {
      const formData = new FormData();
      formData.append("Company", company_id);

      if (form.formData.course.length > 0) {
        form.formData.course.forEach((course) => {
          formData.append("Course", course);
        });
      }
      if (form.formData.trail.length > 0) {
        form.formData.trail.forEach((trail) => {
          formData.append("Trail", trail);
        });
      }
      if (form.formData.tutor.length > 0) {
        form.formData.tutor.forEach((tutor) => {
          formData.append("Tutor", tutor);
        });
      }
      if (form.formData.category.length > 0) {
        form.formData.category.forEach((category) => {
          formData.append("Category", category);
        });
      }
      formData.append("Date_Start", form.formData.date_start);
      formData.append("Date_End", form.formData.date_end);

      await _ReportsServices
        .reportCourses(form.id, formData)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "relatorio-cursos.xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          if (err.response.status === 400) {
            Alerts.alertOf("Não há dados baseados nessa busca");
          } else {
            Alerts.alertOf(
              "Erro ao gerar relatório, por favor tente mais tarde"
            );
          }
        });
    }

    return {
      //------VARIABLES
      listTutor,
      listCourse,
      listTrail,
      listStutendInTrail,
      listCategories,
      //------FUNCTIONS
      validateForm,
      getCourse,
    };
  },
};
</script>

<style lang="scss" scoped>
.box-form-filter {
  height: 65vh;
  overflow-y: auto;
}
</style>
