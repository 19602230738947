<template>
  <form class="q-px-lg" @submit.prevent.stop="">
    <div class="q-mb-md">
      <div class="row items-center">
        <label class="q-mr-sm">Escolha o relatório</label>
        <q-img src="@/assets/icons/info_cicle.svg" width="16px" height="16px" />
        <q-tooltip anchor="top left" self="top left">
          Escolha o relatório para que a tela se adapte ao relatório desejado!
        </q-tooltip>
      </div>
      <q-select
        bg-color="grey-3"
        color="default-pink"
        class="col-3"
        v-model="courseReportValue"
        :options="coursesReports"
        emit-value
        map-options
        outlined
        dense
      />
    </div>
    <div
      class="row col-12 justify-between q-pb-sm"
      v-if="courseReportValue !== 22"
    >
      <div class="col-3">
        <div>
          <span>Curso</span>
        </div>
        <div class="row">
          <SelectWithSearch
            :options="optionsCourse"
            :listOption="listCourse"
            @selectValue="
              (val) => {
                (formCurseReport.course = val), $emit('getCourseId', val);
              }
            "
          />
        </div>
      </div>
    </div>

    <div
      class="row col-10 justify-between q-pb-sm"
      v-if="filterInputsToshow && courseReportValue === 22"
    >
      <div class="col-5">
        <div>
          <span>Nome</span>
        </div>
        <div class="row">
          <q-input
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="object_report_live.Name"
          />
        </div>
      </div>

      <div class="col-5">
        <div>
          <span>Email</span>
        </div>
        <div class="row">
          <q-input
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="object_report_live.Email"
          />
        </div>
      </div>
    </div>

    <div
      class="row col-12 justify-between q-pb-sm"
      v-if="filterInputsToshow && courseReportValue === 22"
    >
      <div class="col-5">
        <div>
          <span>Titulo</span>
        </div>
        <div class="row">
          <q-input
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="object_report_live.Title"
          />
        </div>
      </div>

      <div class="col-5">
        <div>
          <span>Função</span>
        </div>
        <div class="row">
          <q-input
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="object_report_live.Role"
          />
        </div>
      </div>
    </div>

    <div
      class="row col-12 justify-between q-pb-sm"
      v-if="filterInputsToshow && courseReportValue === 22"
    >
      <div class="col-3">
        <div>
          <span>De</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="object_report_live.Date_Start"
          />
        </div>
      </div>

      <div class="col-3">
        <div>
          <span>Até</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            :rules="[
              (val) =>
                val >= object_report_live.Date_Start ||
                'Por favor selecione uma data pós o dia inicial',
            ]"
            v-model="object_report_live.Date_End"
          />
        </div>
      </div>
    </div>

    <div
      class="row col-12 justify-between q-pb-sm"
      v-if="courseReportValue !== 22"
    >
      <div class="col-3">
        <div>
          <span>Categoria</span>
        </div>
        <div class="row">
          <SelectWithSearch
            :options="optionscategory"
            :listOption="listcategory"
            label="Categorias"
            @selectValue="(val) => (formCurseReport.category = val)"
          />
        </div>
      </div>

      <div class="col-3">
        <div>
          <span>Criado por</span>
        </div>
        <div class="row">
          <q-select
            v-model="formCurseReport.tutor"
            :options="optionsTutor"
            bg-color="grey-3"
            class="col-11"
            use-input
            :multiple="true"
            emit-value
            map-options
            outlined
            use-chips
            color="default-pink"
            dense
            @filter="filterFnTutors"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                  <q-item-label caption v-html="opt.caption" />
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
              </q-item> </template
          ></q-select>
        </div>
      </div>

      <div class="col-3">
        <!-- <div>
          <span>Aplicação(falta)</span>
        </div>
        <div class="row">
          <q-select
            v-model="formCurseReport.users"
            :options="listLider"
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            multiple
            outlined
            dense
            emit-value
            map-options
            use-input
            input-debounce="0"
            @filter="
              (val, update) =>
                filterSelect(val, update, optionsUsers, listLider)
            "
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
            <template
              v-slot:option="{ itemProps, opt, selected, toggleOption }"
            >
              <q-item v-bind="itemProps">
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    :model-value="selected"
                    @update:model-value="toggleOption(opt)"
                  />
                </q-item-section>
              </q-item>
            </template>
            <template v-slot:selected>
              <label
                >{{
                  formCurseReport.users != null ? formCurseReport.users.length : 0
                }}
                Usuários
              </label>
            </template>
          </q-select>
        </div> -->
      </div>
    </div>

    <div class="row col-12 justify-between q-pb-sm">
      <div class="col-3" v-if="courseReportValue !== 22">
        <div>
          <span>Período</span>
        </div>
        <div class="row">
          <q-select
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="periodSelect"
            :options="period"
            @update:model-value="(period) => setDataByPeriod(period)"
          />
        </div>
      </div>

      <div class="col-3" v-if="courseReportValue !== 22">
        <div>
          <span>De</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            v-model="formCurseReport.date_start"
          />
        </div>
      </div>

      <div class="col-3" v-if="courseReportValue !== 22">
        <div>
          <span>Até</span>
        </div>
        <div class="row">
          <q-input
            type="date"
            aria-required="true"
            dense
            class="col-12"
            outlined
            color="default-pink"
            bg-color="grey-3"
            :rules="[
              (val) =>
                val >= formCurseReport.date_start ||
                'Por favor selecione uma data pós o dia inicial',
            ]"
            v-model="formCurseReport.date_end"
          />
        </div>
      </div>
    </div>
    <!-- 
    <div class="row col-12 justify-between q-pb-sm">
      <div class="col-3 column">
        <div>
          <span>Usuários</span>
        </div>
        <div class="column">
          <q-toggle v-model="formCurseReport.course_users_inactive" label="Inativos" />
          <q-toggle v-model="formCurseReport.course_users_active" label="Ativos" />
        </div>
      </div>

      <div class="col-3 column">
        <div>
          <span>Cursos</span>
        </div>
        <div class="column">
          <q-toggle
            v-model="formCurseReport.course_active"
            :label="formCurseReport.course_active ? 'Todos' : 'Ativos'"
          />
        </div>
      </div>
      <div class="col-3"></div>
    </div> -->

    <footer class="q-py-md" v-if="courseReportValue !== 22">
      <div class="row justify-end">
        <q-btn
          unelevated
          rounded
          color="default-pink"
          label="Gerar relatório"
          :disable="courseReportValue == null ? true : false"
          @click="
            $emit('sendForm', {
              formData: formCurseReport,
              id: courseReportValue,
            })
          "
        />
      </div>
    </footer>

    <footer class="q-py-md" v-if="courseReportValue == 22">
      <div class="row justify-end">
        <q-btn
          unelevated
          rounded
          color="default-pink"
          label="Gerar relatório"
          :disable="courseReportValue == null ? true : false"
          @click="sendAula"
        />
      </div>
    </footer>
  </form>
</template>

<script>
//----VUEJS
import { computed, reactive, ref, watch } from "vue";

//----COMPONENTS
import Select from "@/components/reports/inputs/Select.vue";
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";
import ReportsServices from "@/services/ReportsServices.js"

export default {
  name: "CourseForm",
  components: {
    Select,
    SelectWithSearch,
  },

  props: {
    listCourse: Array,
    listTrail: Array,
    listTutor: Array,
    listStutendInTrail: Array,
    listcategory: Array,
  },
  emits: ["sendForm", "getCourseId"],

  setup(props) {  
    //------CONSTATNS
    const _reportsServices = new ReportsServices()
    const object_report_live = ref({
      Name: "",
      Email: "",
      Title: "",
      Role: "",
      Date_Start: "",
      Date_End: "",
    });
    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Essa semana",
        type: 4,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Mês atual",
        type: 3,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 3 meses",
        start: -3,
        end: 0,
        type: 2,
      },
      {
        label: "Ano atual",
        type: 5,
      },
      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);

    const coursesReports = ref([
      { value: 4, label: "Relatório de cursos detalhado" },
      { value: 5, label: "Relatório de cursos" },
      { value: 13, label: "Relatório de progresso dos usuários" },
      { value: 14, label: "Relatório de quantidade de usuários" },
      { value: 22, label: "Relatório de aula online" },
    ]);

    const courseReportValue = ref(4);

    //------VARIABLES
    let formCurseReport = reactive({
      course: [],
      trail: [],
      category: [],
      tutor: [],
      date_start: new Date().toISOString().substr(0, 10),
      date_end: new Date().toISOString().substr(0, 10),
    });

    let periodSelect = ref(null);

    let optionsCourse = ref(props.listCourse);
    let optionsTrail = ref(props.listTrail);
    let optionsTutor = ref(props.listTutor);
    let optionscategory = ref(props.listcategory);
    let optionsStudentTrail = ref(props.listStutendInTrail);

    function filterSelect(val, update, options, list) {
      if (val === "") {
        update(() => {
          options.value = list.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options.value = list.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnTutors(val, update) {
      if (val === "") {
        update(() => {
          optionsTutor.value = props.listTutor;
        });
      }
      update(() => {
        const search = val.toLowerCase();
        optionsTutor.value = props.listTutor.filter(
          (v) => v.label.toLowerCase().indexOf(search) > -1
        );
      });
    }

    function filterCourse(val, update, options, list) {
      if (val === "") {
        update(() => {
          options.value = list.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options.value = list.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    async function sendAula(){
      console.log(object_report_live)
      const formData = new FormData();
      formData.append("Name", object_report_live.value.Name);
      formData.append("Email", object_report_live.value.Email);
      formData.append("Title", object_report_live.value.Title);
      formData.append("Role", object_report_live.value.Role);
      formData.append("Date_Start", object_report_live.value.Date_Start);
      formData.append("Date_End", object_report_live.value.Date_End);
      
      await _reportsServices.reportOnlineLesson(formData).then(response =>{
        console.log(response)
      })
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formCurseReport.date_start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formCurseReport.date_end = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formCurseReport.date_start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formCurseReport.date_end = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formCurseReport.date_start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formCurseReport.date_end = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formCurseReport.date_start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formCurseReport.date_end = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formCurseReport.date_start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formCurseReport.date_end = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    const filterInputsToshow = computed(
      () => ![13, 14].includes(courseReportValue.value)
    );

    watch(courseReportValue, (newValue, oldValue) => {
      if ([13, 14].includes(newValue)) {
        formCurseReport.category = [];
        formCurseReport.tutor = [];
      }
    });

    return {
      //------CONSTATNS
      _reportsServices,
      object_report_live,
      period,
      coursesReports,
      courseReportValue,
      filterInputsToshow,

      //------VARIABLES
      formCurseReport,
      periodSelect,
      optionsCourse,
      optionsTrail,
      optionsTutor,
      optionsStudentTrail,
      optionscategory,
      //-----FUNCTIONS
      sendAula,
      filterSelect,
      filterCourse,
      setDataByPeriod,
      filterFnTutors,
    };
  },
};
</script>

<style lang="scss" scoped></style>
