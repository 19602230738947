<template>
  <MoodForm />
</template>

<script>
import MoodForm from "@/components/reports/forms/MoodForm.vue";

export default {
  name: "MoodPage",
  setup() {},
  components: {
    MoodForm,
  },
};
</script>

<style></style>
