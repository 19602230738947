<template>
  <form
    class="q-px-lg"
    @submit.prevent.stop="$emit('sendForm', formUserReport)"
  >
    <div class="container">
      <div class="header">
        <h1>Filtros</h1>
      </div>
      <div class="form-report">
        <div class="select-inputs">
          <div>
            <label for="leader">Líder</label>
            <SelectWithSearch
              :options="optionsLider"
              :listOption="listLider"
              @selectValue="(val) => (formUserReport.Lider = val)"
              :label="'Líderes'"
            />
          </div>
          <div>
            <div class="row items-center">
              <label class="q-mr-sm">Segmentos</label>
              <q-img
                src="@/assets/icons/info_cicle.svg"
                width="16px"
                height="16px"
              />
              <q-tooltip anchor="top left" self="top left">
                Utilize os segmentos para poder filtrar os grupos
              </q-tooltip>
            </div>
            <q-select
              dense
              outlined
              color="default-pink"
              bg-color="grey-3"
              class="col-11"
              multiple
              use-chips
              use-input
              emit-value
              map-options
              @filter="filterFnBranches"
              v-model="formUserReport.branches"
              :options="listBranches"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    Não encontrado
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="container-groups">
            <div class="item-groups">
              <label for="">Grupos</label>
              <q-select
                dense
                outlined
                color="default-pink"
                bg-color="grey-3"
                class="col-11"
                multiple
                use-input
                use-chips
                emit-value
                @filter="filterFnGroups"
                :disable="formUserReport.branches.length > 0 ? false : true"
                map-options
                v-model="formUserReport.Groups"
                :options="optionsGroups"
              >
                <template v-slot:no-option>
                  <q-item>
                    <q-item-section class="text-grey">
                      Não encontrado
                    </q-item-section>
                  </q-item>
                </template>
              </q-select>
            </div>
            <q-checkbox
              left-label
              v-model="allGroups"
              label="Selecionar todos"
              :disable="formUserReport.branches.length > 0 ? false : true"
              color="default-pink"
              @update:model-value="selectedFilteredGroups"
            />
          </div>
        </div>
        <div class="radio-inputs">
          <h2>Período</h2>
          <div class="q-gutter-xl row wrap">
            <div v-for="(item, index) in period" :key="index">
              <q-radio
                v-model="periodSelect"
                :val="item.label"
                :label="item.label"
                color="default-pink"
                @update:model-value="(period) => setDataByPeriod(item)"
              />
            </div>
          </div>
        </div>
        <div class="custom-date">
          <div>
            <label for="from">De</label>
            <q-input
              dense
              v-model="formUserReport.Date_Start"
              id="from"
              type="date"
              outlined
              bg-color="grey-3"
            />
          </div>
          <div>
            <label for="from">Até</label>
            <q-input
              v-model="formUserReport.Date_End"
              id="from"
              dense
              type="date"
              outlined
              bg-color="grey-3"
            />
          </div>
        </div>
      </div>
    </div>
    <footer class="q-py-md">
      <div class="row justify-end q-gutter-sm">
        <q-btn
          unelevated
          outline
          rounded
          color="default-pink"
          label="Gerar lista de usuários"
          @click="() => (showModalUsers = true)"
        />
        <q-btn
          unelevated
          rounded
          color="default-pink"
          label="gerar relatório"
          type="submit"
        />
      </div>
    </footer>
  </form>

  <q-dialog
    v-model="showModalUsers"
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card style="width: 500px">
      <q-card-section>
        <div class="text-h6">Selecione o grupo dos usuários</div>
      </q-card-section>
      <q-card-section>
        <div class="col-3 q-px-lg">
          <div>
            <span>Grupo</span>
          </div>
          <div class="row">
            <Select
              :options="optionsGroups"
              :listOption="listGroups"
              :isMultiple="false"
              :label="getNameById(groupSelected)"
              @selectValue="(val) => (groupSelected = val)"
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions align="right" class="bg-white text-teal">
        <q-btn flat label="Cancelar" v-close-popup color="default-pink" />
        <q-btn
          flat
          label="Gerar excel de usuários"
          color="default-pink"
          @click="$emit('generate', groupSelected)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
//----VUEJS
import { onMounted, reactive, ref, watch } from "vue";

//----QUASAR
import { useQuasar } from "quasar";

//----COMPONENTS
import Select from "@/components/reports/inputs/Select.vue";
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";

/* Services */
import GroupServices from "@/services/GroupServices";

export default {
  name: "UsersForm",

  components: {
    Select,
    SelectWithSearch,
  },
  props: {
    listGroups: Array,
    listLider: Array,
  },
  emits: ["sendForm", "generate"],

  setup(props) {
    //------CONSTATNS
    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },

      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);
    const groupServices = new GroupServices();

    const $q = new useQuasar();

    //------VARIABLES
    let showModalUsers = ref(false);
    let formUserReport = reactive({
      branches: [],
      Lider: [],
      Groups: [],
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
    });
    let groupSelected = ref(null);
    let periodSelect = ref(null);
    let listBranches = ref([]);
    let optionListBranches = ref([]);
    let optionsGroups = ref(props.listGroups);
    let optionsLider = ref(props.listLider);
    let allGroups = ref(false);

    function addSelect(formValue, currentValue) {
      formValue = currentValue;
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formUserReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formUserReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formUserReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formUserReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formUserReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formUserReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formUserReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formUserReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formUserReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formUserReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    async function getBranches() {
      await groupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }
    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          optionsGroups.value = props.listGroups.filter((group) => {
            return formUserReport.branches.includes(group.branch_id);
          });
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          return (
            v.label.toLowerCase().indexOf(needle) > -1 &&
            formUserReport.branches.includes(v.branch_id)
          );
        });
      });
    }

    function getNameById(id) {
      var label = "";
      props.listGroups.forEach((element) => {
        if (element.value == id) {
          label = element.label;
        }
      });

      return label;
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!formUserReport.Groups.includes(group.value)) {
            formUserReport.Groups.push(group.value);
          }
        });
      } else {
        formUserReport.Groups = null;
        formUserReport.Groups = [];
        console.log(formUserReport.Groups);
      }
    }

    watch(
      () => formUserReport.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          formUserReport.Groups = null;
          formUserReport.Groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = props.listGroups;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = props.listGroups.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          formUserReport.Groups = groupValues.filter((value) => {
            if (formUserReport.Groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && formUserReport.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!formUserReport.Groups.includes(group.value)) {
              formUserReport.Groups.push(group.value);
            }
          });
        }
      }
    });

    onMounted(() => {
      getBranches();
    });

    return {
      //------CONSTANTS
      period,

      //------VARIABLES
      formUserReport,
      optionsGroups,
      optionsLider,
      periodSelect,
      showModalUsers,
      groupSelected,
      listBranches,
      optionListBranches,
      allGroups,

      //------FUNCTIONS
      addSelect,
      setDataByPeriod,
      getNameById,
      selectedFilteredGroups,
      filterFnBranches,
      filterFnGroups,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

h1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: #2f2f2f;
}

.form-report {
  margin-top: 2.125rem;
  display: flex;
  flex-direction: column;
}

.form-report label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.25px;
  color: #141519;
  margin-block: 0.25rem;
}

.select-inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-inputs div + div {
  margin-top: 20px;
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}

.radio-inputs {
  margin-top: 1.75rem;
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 1.125rem;
}

.custom-date {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
}

.custom-date label {
  margin-bottom: 0.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #c4c4c4;
}

@media (min-width: 600px) {
  .custom-date {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .custom-date div {
    width: calc(187px);
  }
}
</style>
