<template>
  <form class="q-px-lg" @submit.prevent.stop="">
    <div class="container-fields">
      <div class="field">
        <div>
          <label class="label">Lider</label>
        </div>
        <div>
          <SelectWithSearch
            :options="optionsLider"
            :listOption="listLider"
            @selectValue="(val) => (formPostReport.Lider = val)"
          />
        </div>
      </div>
      <div class="field">
        <div>
          <label class="label">Criador de post</label>
        </div>
        <div class="">
          <SelectWithSearch
            :options="optionsCreatorPost"
            :listOption="listCreatorPost"
            @selectValue="(val) => (formPostReport.CreatePost = val)"
          />
        </div>
      </div>
    </div>
    <div class="container-fields">
      <div class="field">
        <div>
          <label class="label">Post </label>
        </div>
        <div>
          <SelectSearch
            :searchFunction="filterPost"
            :filterFunction="formatItem"
            @selectValue="(val) => (formPostReport.Post = val)"
          />
        </div>
      </div>
      <div class="field">
        <div>
          <label class="label">Categorias</label>
        </div>
        <div>
          <SelectWithSearch
            :options="optionscategory"
            :listOption="listcategory"
            label="Categorias"
            @selectValue="(val) => (formPostReport.Category = val)"
          />
        </div>
      </div>
    </div>
    <div class="container-fields-lg">
      <div class="field-lg">
        <div>
          <div>
            <label class="label">Usuários que interagiram</label>
          </div>
          <div>
            <SelectWithSearch
              :options="optionsUsers"
              :listOption="listLider"
              label="Usuários"
              @selectValue="(val) => (formPostReport.Users = val)"
            />
          </div>
        </div>
      </div>
      <div class="field-lg">
        <div class="row items-center">
          <label for="semgments" class="label q-mr-sm">Segmentos</label>
          <q-img
            src="@/assets/icons/info_cicle.svg"
            width="16px"
            height="16px"
          />
          <q-tooltip anchor="top left" self="top left">
            Utilize os segmentos para poder filtrar os grupos
          </q-tooltip>
        </div>

        <q-select
          dense
          outlined
          color="default-pink"
          bg-color="grey-3"
          class="col-11"
          id="semgments"
          multiple
          use-chips
          use-input
          emit-value
          map-options
          @filter="filterFnBranches"
          v-model="formPostReport.branches"
          :options="listBranches"
        >
          <template v-slot:no-option>
            <q-item>
              <q-item-section class="text-grey">
                Não encontrado
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="field-lg container-groups">
        <div class="item-groups">
          <label for="groups" class="label">Grupos</label>
          <q-select
            dense
            id="groups"
            outlined
            color="default-pink"
            bg-color="grey-3"
            class="col-11"
            multiple
            use-input
            use-chips
            emit-value
            map-options
            input-debounce="0"
            @filter="filterFnGroups"
            :disable="formPostReport.branches.length > 0 ? false : true"
            v-model="formPostReport.Groups"
            :options="optionsGroups"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <q-checkbox
          left-label
          v-model="allGroups"
          label="Selecionar todos"
          :disable="formPostReport.branches.length > 0 ? false : true"
          color="default-pink"
          @update:model-value="selectedFilteredGroups"
        />
      </div>
    </div>
    <div class="radio-inputs">
      <h2>Período</h2>
      <div class="q-gutter-xl row wrap">
        <div v-for="(item, index) in period" :key="index">
          <q-radio
            v-model="periodSelect"
            :val="item.label"
            :label="item.label"
            color="default-pink"
            @update:model-value="(period) => setDataByPeriod(item)"
          />
        </div>
      </div>
    </div>
    <div class="custom-date">
      <div>
        <label for="from">De</label>
        <q-input
          dense
          v-model="formPostReport.Date_Start"
          id="from"
          type="date"
          outlined
          bg-color="grey-3"
        />
      </div>
      <div>
        <label for="from">Até</label>
        <q-input
          v-model="formPostReport.Date_End"
          id="from"
          dense
          type="date"
          outlined
          bg-color="grey-3"
        />
      </div>
    </div>
    <footer class="q-py-md">
      <div class="row justify-end">
        <q-btn
          unelevated
          outline
          rounded
          color="default-pink"
          label="gerar relatório Interação com post"
          @click="
            validateForm()
              ? $emit('sendForm', { formData: formPostReport, id: 2 })
              : null
          "
        />
        <q-btn
          class="q-ml-sm"
          unelevated
          rounded
          color="default-pink"
          label="gerar relatório de Posts"
          @click="
            validateForm()
              ? $emit('sendForm', { formData: formPostReport, id: 1 })
              : null
          "
        />
      </div>
    </footer>
  </form>
</template>

<script>
//----VUEJS
import { reactive, ref, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

//----QUASAR
import { useQuasar } from "quasar";

//----COMPONENTS
import Select from "@/components/reports/inputs/Select.vue";
import SelectSearch from "@/components/reports/inputs/SelectSearch.vue";
import SelectWithSearch from "@/components/reports/inputs/SelectWithSearch.vue";

/* Services */
import GroupServices from "@/services/GroupServices";

export default {
  name: "PostForm",
  components: {
    Select,
    SelectSearch,
    SelectWithSearch,
  },
  props: {
    listGroups: Array,
    listLider: Array,
    listcategory: Array,
    listCreatorPost: Array,
    getPostByTitle: Function,
  },

  emits: ["sendForm"],

  setup(props) {
    //------CONSTATNS
    const period = ref([
      {
        label: "Hoje",
        start: 0,
        end: 0,
        type: 1,
      },
      {
        label: "Ontem",
        start: -1,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 7 dias",
        start: -7,
        end: 0,
        type: 1,
      },
      {
        label: "Últimos 30 dias",
        start: -30,
        end: 0,
        type: 1,
      },

      {
        label: "Últimos 12 meses",
        start: -12,
        end: 0,
        type: 2,
      },
    ]);
    const $q = new useQuasar();
    const route = new useRoute();
    const _GroupServices = new GroupServices();

    //------VARIABLES
    let formPostReport = reactive({
      Category: [],
      Lider: [],
      Groups: [],
      Users: [],
      branches: [],
      Post: [],
      CreatePost: [],
      Date_Start: new Date().toISOString().substr(0, 10),
      Date_End: new Date().toISOString().substr(0, 10),
      Feed_Active: false,
      Feed_Inactive: false,
      Feed_All_Comments: false,
    });
    let listBranches = ref([]);
    let optionListBranches = ref([]);

    if (route.query.postId?.length > 0) {
      formPostReport.Post.push(parseInt(route.query.postId));
    }

    let periodSelect = ref(null);

    let optionsGroups = ref(props.listGroups);
    let optionsLider = ref(props.listLider);
    let optionsUsers = ref(props.listLider);
    let optionsCreatorPost = ref(props.listCreatorPost);
    let optionscategory = ref(props.listcategory);
    let optionsPost = ref([]);
    let allGroups = ref(false);

    function filterSelect(val, update, options, list) {
      if (val === "") {
        update(() => {
          options.value = list.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        options.value = list.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function formatItem(item) {
      return {
        label: item.title,
        value: item.postId,
      };
    }

    function addSelect(formValue, currentValue) {
      formValue = currentValue;
    }

    async function filterPost(val, update) {
      return await props.getPostByTitle(val);
    }

    async function getBranches() {
      await _GroupServices
        .listBranches()
        .then((response) => {
          response.data.map((item) => {
            if (item.status) {
              listBranches.value.push({
                label: item.name_branch,
                value: item.id,
              });
            }
          });
          optionListBranches.value = listBranches.value;
        })
        .catch((err) => {
          console.log(err);
        });
    }

    function filterFnBranches(val, update) {
      if (val === "") {
        update(() => {
          listBranches.value = optionListBranches.value;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        listBranches.value = optionListBranches.value.filter(
          (v) => v.label.toLowerCase().indexOf(needle) > -1
        );
      });
    }

    function filterFnGroups(val, update) {
      if (val === "") {
        update(() => {
          if (formPostReport.branches.length > 0) {
            optionsGroups.value = props.listGroups.filter((group) => {
              return formPostReport.branches.includes(group.branch_id);
            });
          }
          optionsGroups.value = props.listGroups;
        });
      }

      update(() => {
        const needle = val.toLowerCase();
        optionsGroups.value = optionsGroups.value.filter((v) => {
          if (formPostReport.branches.length > 0) {
            return (
              v.label.toLowerCase().indexOf(needle) > -1 &&
              formPostReport.branches.includes(v.branch_id)
            );
          }
          return v.label.toLowerCase().indexOf(needle) > -1;
        });
      });
    }

    function setDataByPeriod(period) {
      var start = new Date();
      var end = new Date();

      switch (period.type) {
        case 1:
          var start_date = start.setDate(start.getDate() + period.start);
          formPostReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formPostReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 2:
          var start_date = start.setMonth(start.getMonth() + period.start);
          formPostReport.Date_Start = new Date(start_date)
            .toISOString()
            .substr(0, 10);

          var end_date = end.setDate(end.getDate() + period.end);
          formPostReport.Date_End = new Date(end_date)
            .toISOString()
            .substr(0, 10);

          return;
        case 3:
          var now = new Date();
          const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
          formPostReport.Date_Start = new Date(firstDay)
            .toISOString()
            .substr(0, 10);

          const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
          formPostReport.Date_End = new Date(lastDay)
            .toISOString()
            .substr(0, 10);

          return;
        case 4:
          var currentDate = new Date();
          var firstday = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          formPostReport.Date_Start = new Date(firstday)
            .toISOString()
            .substr(0, 10);

          var lastday = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 6
            )
          );
          formPostReport.Date_End = new Date(lastday)
            .toISOString()
            .substr(0, 10);

          return;
        default:
          var now = new Date();
          const january = 0;
          const firstDayYear = new Date(now.getFullYear(), january, 1);
          formPostReport.Date_Start = new Date(firstDayYear)
            .toISOString()
            .substr(0, 10);

          const december = 11;
          const lastDayYear = new Date(now.getFullYear(), december, 31);
          formPostReport.Date_End = new Date(lastDayYear)
            .toISOString()
            .substr(0, 10);

          return;
      }
    }

    function validateForm() {
      // if (formPostReport.Category.length < 1) {
      //   _alertOf("Categoria é obrigatória");
      //   return false;
      // } else if (formPostReport.CreatePost.length < 1) {
      //   _alertOf("Criador de post é obrigatório");
      //   return false;
      // } else if (formPostReport.Lider.length < 1) {
      //   _alertOf("Lider é obrigatório");
      //   return false;
      // } else if (formPostReport.Users.length < 1) {
      //   _alertOf("Usuário é obrigatório");
      //   return false;
      // }

      return true;
    }

    function _alertOf(message, color = "red-9") {
      $q.notify({
        message: message,
        color: color,
        position: "top",
      });
    }

    function selectedFilteredGroups() {
      if (allGroups.value) {
        optionsGroups.value.filter((group) => {
          if (!formPostReport.Groups.includes(group.value)) {
            formPostReport.Groups.push(group.value);
          }
        });
      } else {
        formPostReport.Groups = null;
        formPostReport.Groups = [];
      }
    }

    /* LifeCycles */
    onMounted(() => {
      getBranches();
    });

    watch(
      () => formPostReport.branches,
      (newBranch, oldBranch) => {
        if (newBranch.length == 0) {
          formPostReport.Groups = null;
          formPostReport.Groups = [];
          allGroups.value = false;
        }

        if (newBranch.length != oldBranch.length) {
          optionsGroups.value = props.listGroups;
        }

        if (newBranch.length != 0) {
          optionsGroups.value = props.listGroups.filter((group) => {
            return newBranch.includes(group.branch_id);
          });

          let groupValues = optionsGroups.value.map((group) => {
            return group.value;
          });

          formPostReport.Groups = groupValues.filter((value) => {
            if (formPostReport.Groups.includes(value)) {
              return value;
            }
          });
        }
      }
    );

    watch(
      () => formPostReport.Groups,
      (newGroup, oldGroup) => {
        if (newGroup.length == 0) {
          allGroups.value = false;
        }
      }
    );

    watch(optionsGroups, (newGroup, oldGroup) => {
      if (allGroups.value && formPostReport.branches.length != 0) {
        if (newGroup.length > oldGroup.length) {
          optionsGroups.value.filter((group) => {
            if (!formPostReport.Groups.includes(group.value)) {
              formPostReport.Groups.push(group.value);
            }
          });
        }
      }
    });

    return {
      //------CONSTATNS
      period,

      //------VARIABLES
      formPostReport,
      optionsGroups,
      optionsLider,
      optionsUsers,
      optionsCreatorPost,
      optionscategory,
      optionsPost,
      periodSelect,
      optionListBranches,
      listBranches,
      allGroups,
      //------FUNCTIONS
      filterSelect,
      addSelect,
      filterPost,
      setDataByPeriod,
      validateForm,
      formatItem,
      filterFnBranches,
      selectedFilteredGroups,
      filterFnGroups,
    };
  },
};
</script>

<style scoped>
.container-fields {
  margin-bottom: 24px;
}

:is(.container-fields-lg, .container-fields) {
  display: flex;
  flex-direction: column;
}

:is(.field + .field, .field-lg + .field-lg) {
  margin-top: 24px;
}

.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.25px;
  color: #141519;
  margin-block: 0.25rem;
}

.container-groups {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-end;
}

.item-groups {
  width: calc(80% - 25px);
  margin-right: 25px;
}

.radio-inputs {
  margin-top: 1.75rem;
}

h2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #9c9c9c;
  margin-bottom: 1.125rem;
}

.custom-date {
  margin-top: 1.75rem;
  display: flex;
  flex-direction: column;
}

.custom-date label {
  margin-bottom: 0.5rem;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  color: #c4c4c4;
}

@media (min-width: 600px) {
  .custom-date {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }

  .custom-date div {
    width: calc(187px);
  }
}

@media (min-width: 900px) {
  .container-fields {
    flex-direction: row;
    column-gap: 16px;
  }

  .field {
    flex: calc(50% - 16px);
  }

  .field + .field {
    margin-top: 0;
  }
}
</style>
