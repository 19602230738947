<template><ChatForm /></template>

<script>
/* Vue */
import { ref, onMounted } from "vue";

/* Components */
import ChatForm from "@/components/reports/forms/ChatForm.vue";

export default {
  name: "ChatPage",
  components: {
    ChatForm,
  },
  setup() {
    return {};
  },
};
</script>
